<template>
  <div>
    <div class="recommend-cont">
      <div class="subtitle">推荐人信息</div>
      <van-field v-model="referUserPhone" type="tel" label="推荐人手机号:" placeholder="请输入推荐人手机号" :border="false"
        @keyup.13="onSearch" />
      <div class="agent-info" v-if="agentInfo">
        <img :src="agentInfo.headImgUrl" />
        <div class="userinfo">
          <div class="name">{{ agentInfo.userName }}</div>
          <div class="brand-list">
            <div class="brand-item" v-if="allBrandAgent.mzAgent">美妆店主</div>
            <div class="brand-item" v-if="allBrandAgent.djkAgent">大健康店主</div>
          </div>
        </div>
      </div>
      <div class="operate">
        <van-button class="sure-btn" type="primary" color="#FF98A0" @click="getAgentInfo()"
          v-if="!agentInfo || agentInfo.userPhone !== referUserPhone">搜索</van-button>
        <van-button class="sure-btn" type="primary" color="#FF98A0" @click="handleSure()" v-else>确认</van-button>
      </div>
    </div>
    <van-icon name="close" size="30" color="#fff" @click="handleClose()" />
  </div>
</template>

<script>
export default {
  props: {
    brandTypeId: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      referUserPhone: "",//13688155550
      agentInfo: null,
      allBrandAgent: null
    }
  },
  methods: {
    //查询推荐人
    onSearch(e) {
      this.getAgentInfo();
    },
    getAgentInfo() {
      this.isLdAgencies = true;
      this.$toast.loading({ message: "查询中...", duration: 0, forbidClick: true })
      this.get('/OrderGoods/Order/GetPushManDetails', {
        brandTypeId: this.brandTypeId,
        mobile: this.referUserPhone,
        t: new Date().getTime()
      }, 2).then(json => {
        this.$toast.clear();
        if (json && json.code === 1 && json.response) {
          let { djkAgent, mzAgent } = json.response;
          if (this.brandTypeId === 3) {
            this.agentInfo = mzAgent;
          } else {
            this.agentInfo = djkAgent;
          }
          this.allBrandAgent = json.response;
        } else {
          let message = json.msg;
          this.$dialog.confirm({
            title: "提示",
            message: message,
            confirmButtonText: "重新填写",
            cancelButtonText: '再看看',
            confirmButtonColor: "#FF98A0"
          }).then(() => {
            this.referUserPhone = "";
          }).catch(() => {

          });
        }

      }).catch(() => this.$toast.clear());

    },
    //点击确认
    handleSure() {
      if (!this.agentInfo) {
        if (this.referUserPhone) {
          this.getAgentInfo();
          return;
        }
        this.$toast("请填写推荐人信息");
        return
      }
      this.$emit("onsure", { agentInfo: this.agentInfo });
    },
    handleClose() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend-cont {
  width: 300px;
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: #fff;

  .subtitle {
    color: #333333;
    font-size: 15px;
    padding: 20px 0;
    text-align: center;
  }

  .van-field {
    ::v-deep .van-cell__title {
      width: 90px;
      margin-right: 6px;
      line-height: 32px;
    }

    ::v-deep .van-field__value {
      font-size: 12px;
      padding: 3px 5px;
      border-radius: 3px;
      border: 1px solid #EEEEEE;
    }
  }

  .agent-info {
    display: flex;
    padding: 10px 24px 24px;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }

    .userinfo {
      text-align: left;

      .name {
        color: #333333;
        font-size: 14px;
        margin-bottom: 18px;
      }

      .brand-list {
        display: inline-flex;
        align-items: center;

        .brand-item {
          font-size: 12px;
          padding: 3px 10px;
          color: #FF98A0;
          margin-right: 12px;
          border-radius: 11px;
          background-color: #FFE5E7;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .operate {
    padding: 24px 0;
    text-align: center;

    .sure-btn {
      width: 50%;
      height: 36px;
      border-radius: 18px;
    }
  }
}
</style>